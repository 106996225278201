// @ts-strict-ignore
import { configureStore, Store } from '@reduxjs/toolkit'
import { Context, createWrapper } from 'next-redux-wrapper'
import createSagaMiddleware, { Task } from 'redux-saga'
import rootSaga from '../modules/saga/rootSaga'
import rootReducer from '../reducer/rootReducer'

export interface State {
  tick: string
}

export interface SagaStore extends Store {
  sagaTask?: Task
}

const reducer = (state, action) => {
  return rootReducer(state, action)
}

const makeStore = (context: Context) => {
  const sagaMiddleware = createSagaMiddleware()

  const store = configureStore({
    reducer,
    devTools: process.env.ENVIRONMENT === 'local' || process.env.ENVIRONMENT === 'qa',
    middleware: [sagaMiddleware],
  })

  sagaMiddleware.run(rootSaga)

  return store
}

console.log('[store.ts] process.env.NODE_ENV:', process.env.NODE_ENV)
console.log('[store.ts] process.env.ENVIRONMENT:', process.env.ENVIRONMENT)

export const wrapper = createWrapper<Store<State>>(makeStore, {
  debug: process.env.ENVIRONMENT === 'local' || process.env.ENVIRONMENT === 'qa',
})
