// @ts-strict-ignore
import {
  addDays,
  addHours,
  addMonths,
  endOfWeek,
  format,
  getDate,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  startOfWeek,
  subDays,
} from 'date-fns'
import { enUS, ko } from 'date-fns/locale'
import { NextRouter } from 'next/router'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { useConfirmPopup } from '../../components/basic/popup/ConfirmPopup'
import { LOCALE_KO } from '../../modules/i18n/config'
import { s_common, s_schedule } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import {
  API_FETCH_TUTORS_BY_TIME,
  API_FETCH_TUTOR_OPENED_TIME_SCHEDULE,
  API_FETCH_UNBOOK,
  API_FETCH_VALIDATE_COUPON,
  fetchAction,
} from '../../reducer/fetchReducer'
import { RootState } from '../../reducer/rootReducer'
import urls from '../urls'
import { locationMove } from '../utils'
import { fetchTutorOpenedTimeSchedule, fetchTutorsByTime, fetchUnbook, fetchValidateCoupon } from './fetches'

type weekOptionType = { weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 }
export const WEEK_OPTIONS_SUN: weekOptionType = { weekStartsOn: 0 }
export const timeFormatTeens = (date, locale?) => {
  console.log(date, locale)
  return locale === LOCALE_KO || locale === 'kr'
    ? format(date, 'MMM do(E) HH시 mm분', { locale: ko })
    : format(date, 'E, MMM d, hh:mm b', { locale: enUS })
}
export const timeFormatFromObjTeens = (time, locale?) => {
  if (time) {
    return timeFormatTeens(new Date(time.year, time.month - 1, time.day, time.hours, time.minutes), locale)
  }
  return ''
}
export const idFromDate = (date) => format(date, 'yyyy-MM-dd-HH-mm', { locale: ko })
export const idFrom = ({ year, month, day, hours, minutes }) =>
  idFromDate(new Date(year, month - 1, day, hours, minutes))

export const useGetTimeArray = () => {
  const { t, isLocaleKO } = useTranslation()
  return () =>
    Array(48)
      .fill(0)
      .map((v, i) => {
        const hours = Math.floor(i / 2)
        const minutes = (i % 2) * 30
        const isAM = hours < 12 || hours >= 24
        const hours12 = hours % 12 === 0 ? 12 : hours % 12
        const hourStr = isAM ? t(s_schedule.AM_hour(hours12)) : t(s_schedule.PM_hour(hours12))

        let timeStr = ''
        if (isLocaleKO) {
          let time_text = ''
          const time = {
            hour: hours,
            min: minutes,
          }
          if (time.hour == 0 && time.min == 0) {
            time_text = '자정'
          } else if (time.hour == 0 && time.min == 30) {
            time_text = '새벽'
          } else if (time.hour > 0 && time.hour < 5) {
            time_text = '새벽'
          } else if (time.hour >= 5 && time.hour < 12) {
            time_text = '오전'
          } else if (time.hour == 12 && time.min == 0) {
            time_text = '정오'
          } else if (time.hour == 12 && time.min == 30) {
            time_text = '오후'
          } else if (time.hour >= 12 && time.hour < 18) {
            time_text = '오후'
          } else if (time.hour >= 18) {
            time_text = '저녁'
          }
          timeStr = time_text + ' ' + hours + t(s_schedule.o_clock) + (minutes == 0 ? '' : t(s_schedule.thirty_min))
        } else {
          timeStr = hourStr + (minutes == 0 ? t(s_schedule.o_clock) : t(s_schedule.thirty_min))
        }

        return {
          isAM: isAM,
          isPM: !isAM,
          hours: hours,
          hours12: hours12,
          minutes: minutes,
          hourStr: hourStr,
          timeStr: timeStr,
        }
      })
}

export const getWeekDaysSun = (date) => {
  const start = startOfWeek(date, WEEK_OPTIONS_SUN)
  return Array(7)
    .fill(0)
    .map((_, i) => addDays(start, i))
}

export const adjustedUnavailableTimes = (currDay, initialData) => {
  /** 
   * DST 적용되는 타임존(uk, us, ...)의 경우 DST 기간동안 한시적으로 시간 조정된 unavailable_times_2 키값으로 표시한다.
   - 일반 시간대 :  initial.unavailableTimes
   - DST 적용:  initial.unavailableTimes2
   */
  if (!initialData) return []
  const DST_date = initialData?.unavailableTimesDST
    ? new Date(
        initialData.unavailableTimesDST.year,
        initialData.unavailableTimesDST.month - 1,
        initialData.unavailableTimesDST.day,
        0,
        0
      )
    : initialData?.unavailable_times_dst &&
      new Date(
        initialData.unavailable_times_dst.year,
        initialData.unavailable_times_dst.month - 1,
        initialData.unavailable_times_dst.day,
        0,
        0
      )
  if (
    initialData?.unavailableTimes2 == undefined ||
    initialData?.unavailableTimes2?.length == 0 ||
    isAfter(DST_date, startOfWeek(currDay, WEEK_OPTIONS_SUN))
  ) {
    return initialData.unavailableTimes || initialData.unavailable_times
  } else {
    return initialData.unavailableTimes2
  }
}

export const useGetTimeSlotsData = () => {
  const { t } = useTranslation()
  const { day, initial, currentCredit } = useSelector((state: RootState) => ({
    currentCredit: state.schedule.step1.currentCredit,
    initial: state.schedule.initial,
    day: state.schedule.step1.day,
  }))
  const getTimeArray = useGetTimeArray()
  return () => {
    if (!(initial && day && currentCredit)) {
      return null
    }
    const startDate = startOfWeek(day, WEEK_OPTIONS_SUN)
    const timeSlots = [[], [], [], [], [], [], [], [], []]
    const getReserved = (date) => initial.reserved[idFromDate(date)]
    const isNoTutors40 = (date) => initial.noTutors40[idFromDate(date)]
    const isNoTutors20 = (date) => initial.noTutors20[idFromDate(date)]
    const isCreditNotAllowedHours = (hours) => {
      if (currentCredit.allowedHours) {
        return !currentCredit.allowedHours.some((v) => v == hours)
      }
      return false
    }
    Array(9)
      .fill(0)
      .map((_, i) => {
        const curDate = addDays(subDays(startDate, 1), i)
        const year = getYear(curDate)
        const month = getMonth(curDate) + 1
        const day = getDate(curDate)
        getTimeArray().map((item) => {
          const hours = item.hours
          const isAM = item.isAM
          const hours12 = item.hours12
          const minutes = item.minutes
          const date = new Date(year, month - 1, day, hours, minutes)
          const serviceBreak = isServiceBreak(adjustedUnavailableTimes(day, initial), hours, minutes, year, month, day)
          const creditNotAllowedHours = isCreditNotAllowedHours(hours)
          const bygone = isBefore(date, initial.today)
          const within26Hours = isBefore(date, addHours(initial.today, 24 + 2))
          const slotData = {
            id: idFromDate(date),
            date: date,
            year: year,
            month: month,
            day: day,
            hours12: hours12,
            hours: hours,
            minutes: minutes,
            isAM: isAM,
            isPM: !isAM,
            isOClock: minutes == 0,
            timeStr: item.timeStr,
            isWithin24Hours: within26Hours,
            isBygone: bygone,
            isServiceBreak: serviceBreak,
            reserved: getReserved(date),
            isCreditNotAllowed: creditNotAllowedHours,
            isNoTutors40: undefined,
            isNoTutors20: undefined,
            isNoHover: undefined,
            tooltip: undefined,
          }
          slotData.isNoHover =
            slotData.isBygone ||
            slotData.isWithin24Hours ||
            slotData.isServiceBreak ||
            slotData.isCreditNotAllowed ||
            slotData.reserved
          slotData.isNoTutors40 =
            slotData.isBygone ||
            slotData.isWithin24Hours ||
            slotData.isServiceBreak ||
            slotData.isCreditNotAllowed ||
            isNoTutors40(date)
          slotData.isNoTutors20 =
            slotData.isBygone ||
            slotData.isWithin24Hours ||
            slotData.isServiceBreak ||
            slotData.isCreditNotAllowed ||
            isNoTutors20(date)

          if (slotData.isBygone) {
            slotData.tooltip = t(s_schedule.the_past_time)
          } else if (slotData.isWithin24Hours) {
            slotData.tooltip = t(s_schedule.you_cannot_register_within_26_hours)
          } else if (slotData.isCreditNotAllowed) {
            slotData.tooltip = t(s_schedule.you_cannet_register_with_the_selected_credits)
          }

          timeSlots[i].push(slotData)
        })
      })
    return timeSlots
  }
}

export const isNotAllowSelectedDay = (initial, date) => {
  return (
    isBefore(date, startOfWeek(initial.today, WEEK_OPTIONS_SUN)) ||
    isAfter(date, endOfWeek(initial.toMonth, WEEK_OPTIONS_SUN))
  )
}

/**
 서비스 미운영 시간 or DST(서머타임)적용으로 인한 loss 시간대 표시
 */
export const isServiceBreak = (unavailables, hours, minutes, year?, month?, day?) => {
  if (year && month && day) {
    return unavailables.some(
      (v) => v.h == hours && v.m == minutes && v.year == year && v.month == month && v.day == day
    )
  } else {
    return unavailables.some((v) => v.h == hours && v.m == minutes)
  }
}

export const useNoCreditPopup = () => {
  const confirmPopup = useConfirmPopup()
  const { t } = useTranslation()
  return {
    show: () => {
      confirmPopup.show({
        id: 'confirm-popup',
        leftBtn: false,
        noCloseBtn: true,
        body: <div>{t(s_schedule.you_are_out_of_credits_please_choose_another_credit)}</div>,
      })
    },
  }
}

export const tutorAvailableDaysSelector = createSelector(
  (state: RootState) => state.fetch[API_FETCH_TUTOR_OPENED_TIME_SCHEDULE],
  ({ data }) => {
    if (data) {
      const obj = {}
      data.dataArray.map((v) => {
        if (v.is_applicable) {
          const date = new Date(v.year, v.month - 1, v.day, v.hours, v.minutes)
          const id = idFromDate(date)
          obj[id] = date
        }
      })
      return Object.keys(obj).map((key) => obj[key])
    }
    return []
  }
)

export const tutorOpenedTimesSelector = createSelector(
  (state: RootState) => state,
  (state) => {
    const data = state.fetch[API_FETCH_TUTOR_OPENED_TIME_SCHEDULE].data
    const tutorFirstTutor = state.schedule.step1.tutorFirst.tutor
    if (data && tutorFirstTutor) {
      const obj = {}
      data.dataArray.map((v) => {
        const date = new Date(v.year, v.month - 1, v.day, v.hours, v.minutes)
        const id = idFromDate(date)
        obj[id] = {
          userapply_id: v.userapply_id,
          is_applicable: v.is_applicable,
          is_booking_finished: v.is_booking_finished,
        }
      })
      return obj
    }
    return {}
  }
)

export const creditCountSelector = createSelector(
  (state: RootState) => state.schedule.completed,
  (completed) => {
    const count = {}
    for (const id in completed) {
      const creditId = completed[id].credit.id
      if (count[creditId] > 0) {
        count[creditId]++
      } else {
        count[creditId] = 1
      }
    }
    return (credit) => {
      return credit.unusedCoupon - (count[credit.id] ? count[credit.id] : 0)
    }
  }
)

export const completedLessonCounter = createSelector(
  (state: RootState) => state.schedule.completed,
  (completed) => Object.keys(completed).length
)

export const completedLessonTutorCounter = createSelector(
  (state: RootState) => state.schedule.completed,
  (completed) => {
    const values = Object.values(completed)

    if (values.length === 0) return 0

    const selectedUniqueTutorIds = Object.keys(
      values
        .map((value: any) => value.tutor.id)
        .reduce((acc, tutorId) => {
          acc[tutorId] = true
          return acc
        }, {})
    )

    return selectedUniqueTutorIds.length
  }
)

export const useUnbookTime = () => {
  const dispatch = useDispatch()
  return (userapply_id, credit, callback = undefined) => {
    dispatch(
      fetchAction(
        API_FETCH_UNBOOK,
        fetchUnbook,
        {
          tutor_userapply_id: userapply_id,
          is40m: credit.is40M,
        },
        callback
      )
    )
  }
}

export const useLoadTutorSchedule = () => {
  const initial = useSelector((state: RootState) => state.schedule.initial)

  const dispatch = useDispatch()
  return (tutorId, credit) => {
    dispatch(
      fetchAction(API_FETCH_TUTOR_OPENED_TIME_SCHEDULE, fetchTutorOpenedTimeSchedule, {
        from: initial.today,
        to: addMonths(initial.today, 3),
        tutorId: tutorId,
        coupon_type_id: credit?.coupon_type_id, // coupon_bundle_id로 대체되어 deprecate 예정
        lesson_id: initial.lessonId,
        coupon_bundle_id: credit?.id,
      })
    )
  }
}

export const useValidateCoupon = () => {
  const currentCredit = useSelector((state: RootState) => state.schedule.step1.currentCredit)
  const dispatch = useDispatch()
  return (userapply_id, completed, callback) => {
    dispatch(
      fetchAction(
        API_FETCH_VALIDATE_COUPON,
        fetchValidateCoupon,
        {
          duration: currentCredit.is40M ? 40 : 20,
          tutor_userapply_id: userapply_id,
          coupon_bundle_id: currentCredit.id,
          coupon_type_id: currentCredit.coupon_type_id,
          selected_coupon_ids: Object.keys(completed).map((id) => completed[id]?.coupon_id),
        },
        callback
      )
    )
  }
}

export const useLoadTutorsByTime = () => {
  const { currentCredit, selectedTime } = useSelector(
    (state: RootState) => ({
      currentCredit: state.schedule.step1.currentCredit,
      selectedTime: state.schedule.step1.timeFirst.time,
    }),
    shallowEqual
  )

  const dispatch = useDispatch()
  return () => {
    if (selectedTime != null) {
      dispatch(
        fetchAction(API_FETCH_TUTORS_BY_TIME, fetchTutorsByTime, {
          year: selectedTime.year,
          month: selectedTime.month,
          day: selectedTime.day,
          hours: selectedTime.hours,
          minutes: selectedTime.minutes,
          creditId: currentCredit.coupon_type_id, // deprecate 예정
          bundleId: currentCredit.id,
        })
      )
    }
  }
}

export const useGetTimeofTheDay = () => {
  const { isLocaleKO } = useTranslation()
  return (date) => {
    const hours = Math.floor(date.getHours())
    const minutes = date.getMinutes()

    const time = {
      hour: hours,
      min: minutes,
    }
    if (isLocaleKO) {
      if (time.hour == 0 && time.min == 0) {
        return `자정 ${format(new Date(date), 'hh:mm')}`
      } else if (time.hour == 0 && time.min == 30) {
        return `새벽 ${format(new Date(date), 'KK:mm')}`
      } else if (time.hour >= 0 && time.hour < 5) {
        return `새벽 ${format(new Date(date), 'KK:mm')}`
      } else if (time.hour >= 5 && time.hour < 12) {
        return `오전 ${format(new Date(date), 'hh:mm')}`
      } else if (time.hour == 12 && time.min == 0) {
        return `정오 ${format(new Date(date), 'hh:mm')}`
      } else if (time.hour == 12 && time.min == 30) {
        return `오후 ${format(new Date(date), 'HH:mm')}`
      } else if (time.hour >= 12 && time.hour < 18) {
        return `오후 ${format(new Date(date), 'HH:mm')}`
      } else if (time.hour >= 18) {
        return `저녁 ${format(new Date(date), 'HH:mm')}`
      }
    } else {
      if (time.hour >= 12) {
        return `PM ${format(new Date(date), 'hh:mm')}`
      } else {
        return `AM ${format(new Date(date), 'hh:mm')}`
      }
    }
  }
}
export const dayOfWeeks = (number, t) => {
  const days = [
    t(s_common.sun),
    t(s_common.mon),
    t(s_common.tue),
    t(s_common.wed),
    t(s_common.thu),
    t(s_common.fri),
    t(s_common.sat),
  ]
  return days[number]
}

export const isWeeksAfter = (n, today, day) => {
  return isAfter(day, addDays(startOfWeek(today, WEEK_OPTIONS_SUN), n * 7))
}

export const exitToDashboard = (router: NextRouter, childId: number) => {
  const url = childId ? urls.teens.portal.dashboard.home : urls.teens.portal.lessons.scheduleEnter
  locationMove(router, url)
}
