import { COUNTRY_ALPHA2_CODE, COUNTRY_CURRENCY } from '../vars'

export const PRICING_CURRENCY_OPTIONS = [
  {
    country_code: COUNTRY_ALPHA2_CODE.JP,
    name: '¥ JPY',
    currency: COUNTRY_CURRENCY.JPN,
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.TW,
    name: '$ TWD',
    currency: COUNTRY_CURRENCY.TWD,
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.US,
    name: '$ USD',
    currency: COUNTRY_CURRENCY.USD,
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.KR,
    name: '₩ KRW',
    currency: COUNTRY_CURRENCY.KRW,
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.VN,
    name: '₫ VND',
    currency: COUNTRY_CURRENCY.VND,
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.CN,
    name: '¥ CNY',
    currency: COUNTRY_CURRENCY.CNY,
  },
]

export const CURRENCY_LIST = [
  {
    country_code: COUNTRY_ALPHA2_CODE.JP,
    name: 'JPY',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.TW,
    name: 'TWD',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.US,
    name: 'USD',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.KR,
    name: 'KRW',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.VN,
    name: 'VND',
  },
  {
    country_code: COUNTRY_ALPHA2_CODE.CN,
    name: 'CNY',
  },
]
