// @ts-strict-ignore
import { createSlice } from '@reduxjs/toolkit'

const searchBarSlice = createSlice({
  name: 'searchBar',
  initialState: {
    isOpen: false,
    materialPayload: {
      keyword: null,
      large_category_id: null,
    },
    blogPayload: {
      keyword: null,
    },
    resourcePayload: {
      keyword: null,
    },
    tutorPayload: {
      gender: { key: '2', name: null },
      major: { key: '5', name: null },
      accent: { key: '5', name: null },
      search: null,
      type: 'all',
      page: 1,
    },
    webinarPayload: {
      keyword: null,
      webinar_large_hashtag_id: null,
    },
    resultMaterial: { courses: [] },
    resultBlog: null,
    resultResource: null,
    resultWebinar: { webinars: [] },
    showResult: false,
    placeholder: '',
    placeholderShort: '',
  },
  reducers: {
    initialize(state) {
      state.isOpen = false
      state.showResult = false
      state.webinarPayload = { keyword: null, webinar_large_hashtag_id: null }
      ;(state.materialPayload = {
        keyword: null,
        large_category_id: null,
      }),
        (state.blogPayload = {
          keyword: null,
        }),
        (state.resourcePayload = {
          keyword: null,
        }),
        (state.tutorPayload = {
          gender: { key: '2', name: null },
          major: { key: '5', name: null },
          accent: { key: '5', name: null },
          search: null,
          type: 'all',
          page: 1,
        })
    },
    show(state) {
      state.isOpen = true
    },
    close(state) {
      state.isOpen = false
    },
    setKeyword(state, action) {
      state.materialPayload.keyword = action.payload
      state.blogPayload.keyword = action.payload
      state.resourcePayload.keyword = action.payload
      state.webinarPayload.keyword = action.payload
      state.tutorPayload.search = action.payload
    },
    setMaterialCategory(state, action) {
      state.materialPayload.large_category_id = action.payload
    },
    setTutorPayload(state, action) {
      state.tutorPayload.gender = action.payload.gender
      state.tutorPayload.major = action.payload.major
      state.tutorPayload.accent = action.payload.accent
      state.tutorPayload.type = action.payload.type
      state.tutorPayload.page = action.payload.page
    },
    setTutorType(state, action) {
      state.tutorPayload.type = action.payload.type
    },
    searchMaterial(state, action) {
      state.resultMaterial = action.payload
    },
    setShowResult(state) {
      state.showResult = true
    },
    setHideResult(state) {
      state.showResult = false
    },
    setPlaceholder(state, action) {
      state.placeholder = action.payload
    },
    setPlaceholderShort(state, action) {
      state.placeholderShort = action.payload
    },
  },
})

export const searchBarReducer = searchBarSlice.reducer
export const searchBarActions = searchBarSlice.actions
