// @ts-strict-ignore
import { combineReducers } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { brazeReducer } from '../reducer/brazeReducer'
import editReducer from '../reducer/editReducer'
import fetchReducer from '../reducer/fetchReducer'
import { popupReducer } from '../reducer/popupReducer'
import { questionReducer } from '../reducer/questionReducer'
import reviewReducer from '../reducer/reviewReducer'
import scheduleReducer from '../reducer/scheduleReducer'
import { searchBarReducer } from '../reducer/searchBarReducer'
import { appReducer } from './appReducer'
import createChildAccountReducer from './createChildAccountReducer'
import { logDataReducer } from './logDataReducer'
import profileReducer from './profileReducer'
import { tutorReducer } from './tutorReducer'

const rootReducer = (state, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state }
    default: {
      const combineReducer = combineReducers({
        app: appReducer,
        popup: popupReducer,
        schedule: scheduleReducer,
        fetch: fetchReducer,
        edit: editReducer,
        review: reviewReducer,
        logData: logDataReducer,
        tutor: tutorReducer,
        searchBar: searchBarReducer,
        question: questionReducer,
        braze: brazeReducer,
        profile: profileReducer,
        createChild: createChildAccountReducer,
      })
      return combineReducer(state, action)
    }
  }
}

export default rootReducer

export type RootState = {
  app: ReturnType<typeof appReducer>
  searchBar: ReturnType<typeof searchBarReducer>
  question: ReturnType<typeof questionReducer>
  popup: ReturnType<typeof popupReducer>
  schedule: ReturnType<typeof scheduleReducer>
  fetch: ReturnType<typeof fetchReducer>
  edit: ReturnType<typeof editReducer>
  review: ReturnType<typeof reviewReducer>
  logData: ReturnType<typeof logDataReducer>
  tutor: ReturnType<typeof tutorReducer>
  braze: ReturnType<typeof brazeReducer>
  profile: ReturnType<typeof profileReducer>
  createChild: ReturnType<typeof createChildAccountReducer>
}
