// @ts-strict-ignore
import { Dialog, Transition } from '@headlessui/react'
import { isFunction } from 'lodash'
import { Fragment, useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useModalBackButton from '../../../modules/hooks/common/useModalBackButton'
import { popupActions } from '../../../reducer/popupReducer'
import { RootState } from '../../../reducer/rootReducer'
import Col from '../../basic/Col'

export interface SplitPopupPayload {
  id: string
  title?: any
  bodyRight: any
  bodyLeft: any
  onClose?: any
  noBorder?: boolean
  isSeparated?: boolean
}

export const useSplitPopup = () => {
  const dispatch = useDispatch()
  const show = (props: SplitPopupPayload) => {
    dispatch(popupActions.show({ ...props, type: 'split' }))
  }
  const close = (id) => {
    dispatch(popupActions.close({ id }))
  }
  return { show, close }
}

export const SplitPopup = ({ id }: { id: any }) => {
  const dispatch = useDispatch()
  const focusRef = useRef(null)
  // const router = useRouter()
  const { data, isShow } = useSelector(
    (state: RootState) => ({
      data: state.popup.data[id],
      isShow: state.popup.isPopupRendered,
    }),
    shallowEqual
  )
  const open = data ? true : false
  const {
    title = '',
    onClose = null,
    bodyLeft = undefined,
    bodyRight = undefined,
    noBorder = false,
    isSeparated = false,
  } = data ? data : {}

  const setOpen = (show) => {
    if (!show) {
      dispatch(popupActions.close({ id }))
      if (isFunction(onClose)) {
        onClose()
      }
    }
  }

  useModalBackButton(id, isShow)

  useEffect(() => {
    return () => {
      dispatch(popupActions.close({ id }))
    }
  }, [])

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="z-3000 fixed inset-0 top-8 bottom-8 overflow-y-hidden"
        initialFocus={focusRef}
        open={open}
        onClose={setOpen}
      >
        <button ref={focusRef} className="hidden" />
        <div className="relative flex h-screen items-end justify-center p-[8px]">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}

          <span aria-hidden="true" className="hidden h-screen align-middle sm:inline-block">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block h-screen w-screen transform overflow-x-auto overflow-y-hidden align-middle transition-all">
              <div className="flex h-full w-full min-w-[1040px] flex-col rounded-[8px] bg-white shadow-lg">
                <div className="smd:hidden relative m-[0px] flex h-[156px] flex-none items-end rounded-t-[8px]">
                  <div className="mx-auto mb-[32px] w-full max-w-[1000px]">
                    <Dialog.Title as="h1" className="text-h1 font-bold">
                      {title}
                    </Dialog.Title>
                  </div>
                  {/* CANCEL BUTTON */}
                  <div className="absolute top-[48px] right-[48px] flex h-[20px] w-[20px] items-center justify-center">
                    <button
                      type="button"
                      className="appearance-none border-none focus:outline-none "
                      style={{ width: 20, height: 20 }}
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setOpen(false)
                      }}
                    >
                      <i className="fe fe-x text-24"></i>
                    </button>
                  </div>
                </div>
                {/* ------- */}
                {/* 모바일 */}
                {/* ------- */}
                <div
                  style={{ minHeight: '95px' }}
                  className="relative m-[0px] flex flex-none items-end rounded-t-[5px] pt-[56px] sm:hidden"
                >
                  <div className="mb-[16px] w-full px-[40px] text-center">
                    <Dialog.Title as="h1" className="text-h3 font-medium">
                      {title}
                    </Dialog.Title>
                  </div>
                  <div className="absolute top-[20px] right-[20px] flex h-[20px] w-[20px] items-center justify-center">
                    {/* 모바일용 닫기 버튼 */}
                    <button
                      type="button"
                      className="appearance-none border-none focus:outline-none "
                      style={{ width: 20, height: 20 }}
                      onClick={() => setOpen(false)}
                    >
                      <i className="fe fe-x text-24"></i>
                    </button>
                  </div>
                </div>
                {/* ------- */}
                {/* 본문 영역 */}
                {/* ------- */}
                {isSeparated ? (
                  <div className="no-scrollbar relative mx-auto flex max-w-[1080px] flex-row justify-center overflow-y-scroll">
                    <Col clx="col-7 no-scrollbar overflow-y-scroll" relative rounded border1={!noBorder} borderGray500>
                      {bodyLeft}
                    </Col>
                    <Col clx="ml-[21px] col-4 overflow-y-scroll no-scrollbar">{bodyRight}</Col>
                  </div>
                ) : (
                  <div
                    style={{ transform: 'translateX(-50%)' }}
                    className="row no-gutters no-scrollbar relative left-1/2 max-w-[1000px] flex-1 transform overflow-y-scroll"
                  >
                    <Col
                      clx="col-8 no-scrollbar overflow-y-scroll"
                      relative
                      rounded
                      style={{ maxHeight: '95%' }}
                      left0
                      border1={!noBorder}
                      borderGray500
                    >
                      {bodyLeft}
                    </Col>
                    <Col clx="col-4 overflow-y-scroll no-scrollbar">{bodyRight}</Col>
                  </div>
                )}

                <div className="lgd:hidden flex-none rounded-b-[8px]" />
                <div className="h-[8px] flex-none rounded-b-[5px] lg:hidden" />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
