// @ts-strict-ignore
import { Dialog, Transition } from '@headlessui/react'
import { isFunction } from 'lodash'
import { Fragment, useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useIsTablet from '../../../modules/hooks/common/useIsTablet'
import useModalBackButton from '../../../modules/hooks/common/useModalBackButton'
import { s_common } from '../../../modules/i18n/strings/auto/wording'
import useTranslation from '../../../modules/i18n/useTranslation'
import { popupActions } from '../../../reducer/popupReducer'
import { RootState } from '../../../reducer/rootReducer'
import Button from '../../_legacy/Button'

export const useAlertPopup = () => {
  const dispatch = useDispatch()
  const show = (props: { id; body; onClose?; btnLabel?; title? }) => {
    dispatch(popupActions.show({ ...props, type: 'alert' }))
  }
  const close = (id) => {
    dispatch(popupActions.close({ id }))
  }
  return { show, close }
}

export const AlertPopup = ({ id }: { id: any }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const focusRef = useRef(null)
  const { data, isShow } = useSelector(
    (state: RootState) => ({
      data: state.popup.data[id],
      isShow: state.popup.isPopupRendered,
    }),
    shallowEqual
  )
  const open = data ? true : false
  const { title = '', onClose = null, body = undefined, btnLabel = undefined } = data ? data : {}

  const closeHandler = () => {
    dispatch(popupActions.close({ id }))
    if (isFunction(onClose)) {
      onClose()
    }
  }
  const [isTablet, setIsTablet] = useIsTablet()

  useModalBackButton(id, isShow)

  useEffect(() => {
    return () => {
      dispatch(popupActions.close({ id }))
    }
  }, [])

  return (
    <div id={id}>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="z-3000 fixed inset-0 flex items-center justify-center overflow-y-auto"
          initialFocus={focusRef}
          open={open}
          onClose={() => {
            // do nothing
          }}
        >
          <button ref={focusRef} className="hidden" />
          <div className="flex-1 p-[8px]">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span aria-hidden="true" className="hidden sm:align-middle">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="mx-auto max-w-[600px] transform overflow-hidden align-middle transition-all">
                <div className="flex flex-col rounded-[8px] bg-white shadow-lg">
                  <div className="relative m-[0px] flex items-end rounded-t-[8px] px-[32px] pt-[32px] pb-[16px]">
                    <div className="w-full">
                      <Dialog.Title
                        as="h1"
                        className="text-h2 mx-auto whitespace-normal pr-20 font-bold"
                        style={{ wordBreak: 'keep-all' }}
                      >
                        {title}
                      </Dialog.Title>
                    </div>
                    {/* CANCEL BUTTON */}
                    <div className="absolute top-[34px] right-[32px] flex h-[20px] w-[20px] items-center justify-center">
                      <button
                        type="button"
                        className="appearance-none border-none focus:outline-none"
                        style={{ width: 20, height: 20 }}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          closeHandler()
                        }}
                      >
                        <i className="fe fe-x"></i>
                      </button>
                    </div>
                  </div>
                  {/* BODY */}
                  <div
                    style={{ wordBreak: 'keep-all' }}
                    className="text-sBody max-w-[600px] transform px-[32px] pt-[0px] pb-[32px] transition-all"
                  >
                    <div className="pb-[40px]">{body}</div>
                    {/* BUTTON AREA */}
                    <div className="float-right">
                      <Button primary md={!isTablet} sm={isTablet} onClick={closeHandler}>
                        {btnLabel ? btnLabel : t(s_common.ok)}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
