// @ts-strict-ignore
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { isFunction } from 'lodash'
import { Fragment, useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useModalBackButton from '../../../modules/hooks/common/useModalBackButton'
import useTranslation from '../../../modules/i18n/useTranslation'
import { ICON_X_BLACK } from '../../../modules/svg/svg'
import { popupActions } from '../../../reducer/popupReducer'
import { RootState } from '../../../reducer/rootReducer'

export interface BasicPopupPayload {
  id: string
  title?: any
  onClose?: any
  onOutsideClose?: () => void
  body: any
  overlayClose?: boolean
  maxW?: number | string
  maxH?: number | string
  bodyPx?: number
  bodyPb?: number
  titlePx?: number
  titlePt?: number
  titlePb?: number
  noScrollbar?: boolean
  noClose?: boolean
  noOutsideClose?: boolean
  outsideCloseTextKey?: string[] | JSX.Element[]
  noTopOutsideClose?: boolean
  outsideTopCloseText?: string
  noTitle?: boolean
  titleCentered?: boolean
  noRounded?: boolean
  clx?: any
  bgColor?: any
  closeButtonRightPixel?: number
  closeButtonTopPixel?: number
  bottomButton?: React.ReactNode
  wrapperClassName?: string
}

export const useBasicPopup = () => {
  const dispatch = useDispatch()
  const show = (props: BasicPopupPayload) => {
    dispatch(popupActions.show({ ...props, type: 'basic' }))
  }

  const close = (id) => {
    dispatch(popupActions.close({ id }))
  }

  return { show, close }
}

export const BasicPopup = ({ id }: { id: any }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const focusRef = useRef(null)

  const { data, isShow } = useSelector(
    (state: RootState) => ({
      data: state.popup.data[id],
      isShow: state.popup.isPopupRendered,
    }),
    shallowEqual
  )

  const open = data ? true : false

  const {
    title = '',
    onClose = null,
    onOutsideClose = null,
    body = undefined,
    overlayClose = false,
    maxW = 724,
    maxH = 'calc(100vh - 100px)',
    bodyPx = 32,
    bodyPb = 40,
    titlePx = 32,
    titlePt = 16,
    titlePb = 16,
    noScrollbar = false,
    noClose = false,
    noOutsideClose = true,
    outsideCloseTextKey = [],
    noTopOutsideClose = true,
    outsideTopCloseText = '',
    noTitle = false,
    titleCentered = false,
    noRounded = false,
    clx = '',
    bgColor = 'bg-white',
  } = data ? data : {}

  const closeHandler = () => {
    dispatch(popupActions.close({ id }))
    if (isFunction(onClose)) {
      onClose()
    }
  }

  useModalBackButton(id, isShow)

  const outsideCloseHandler = () => {
    dispatch(popupActions.close({ id }))
    if (isFunction(onOutsideClose)) {
      onOutsideClose()
    }
  }

  useEffect(() => {
    return () => {
      dispatch(popupActions.close({ id }))
    }
  }, [])

  return (
    <div id={id}>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="z-3000 fixed inset-0 flex items-center justify-center overflow-y-auto"
          initialFocus={focusRef}
          open={open}
          onClose={() => {
            if (overlayClose) {
              closeHandler()
            }
          }}
        >
          <button ref={focusRef} className="hidden" />
          <div className="flex-1 p-[8px]">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="z-aitp bg-black-overlay fixed inset-0 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span aria-hidden="true" className="hidden sm:align-middle">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
            >
              <div
                className={classNames('mx-auto transform overflow-hidden align-middle transition-all', clx)}
                style={{ maxWidth: maxW }}
              >
                {!noTopOutsideClose && (
                  <div
                    style={{
                      lineHeight: '21px',
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      outsideCloseHandler()
                    }}
                    className="mb-[8px] flex cursor-pointer justify-end text-[14px] font-medium text-white"
                  >
                    <span>X</span>
                    <span className="ml-[12px]">{outsideTopCloseText}</span>
                  </div>
                )}
                <div className={classNames({ 'rounded-[16px]': !noRounded }, bgColor, 'flex flex-col')}>
                  <div
                    className={classNames(
                      noTitle && 'pt-0 pb-0',
                      'relative m-[0px] flex items-end rounded-t-[5px] px-[32px]'
                    )}
                    style={{
                      paddingLeft: !noTitle && titlePx,
                      paddingRight: !noTitle && titlePx,
                      paddingTop: !noTitle && titlePt,
                      paddingBottom: !noTitle && titlePb,
                    }}
                  >
                    {title && (
                      <div className="w-full">
                        <Dialog.Title
                          as="h1"
                          className={classNames(
                            titleCentered && 'text-center',
                            'text-h2 mx-auto whitespace-normal pr-20 font-bold '
                          )}
                          style={{ wordBreak: 'keep-all' }}
                        >
                          {title}
                        </Dialog.Title>
                      </div>
                    )}
                    {/* CANCEL BUTTON */}
                    {!noClose && (
                      <div className="absolute top-[34px] right-[32px] z-10 flex h-[20px] w-[20px] items-center justify-center">
                        <button
                          type="button"
                          className="appearance-none border-none focus:outline-none"
                          style={{ width: 20, height: 20 }}
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            closeHandler()
                          }}
                        >
                          <img src={ICON_X_BLACK} />
                        </button>
                      </div>
                    )}
                  </div>

                  {/* BODY */}
                  <div
                    className="text-sBody transform rounded p-0 transition-all"
                    style={{ wordBreak: 'keep-all', maxWidth: maxW }}
                  >
                    <div
                      className={classNames('z-auto overflow-y-auto', {
                        'no-scrollbar': noScrollbar,
                      })}
                      style={{
                        maxHeight: maxH,
                        paddingLeft: bodyPx,
                        paddingRight: bodyPx,
                        paddingBottom: bodyPb,
                      }}
                    >
                      {body}
                    </div>
                  </div>
                </div>

                {/* Hide Button Outside Modal */}
                {!noOutsideClose && (
                  <div
                    style={{
                      color: '#869AB8',
                      lineHeight: '21px',
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      outsideCloseHandler()
                    }}
                    className="cursor-pointer text-[14px] font-medium"
                  >
                    <span className="mr-[6px]">{t(outsideCloseTextKey)}</span>
                    <span>X</span>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
