// @ts-strict-ignore
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { isFunction } from 'lodash'
import { Fragment, useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useModalBackButton from '../../../modules/hooks/common/useModalBackButton'
import useTranslation from '../../../modules/i18n/useTranslation'
import { popupActions } from '../../../reducer/popupReducer'
import { RootState } from '../../../reducer/rootReducer'

export interface SidePopupPayload {
  id: string
  body: any
  title?: any
  onClose?: any
  overlayClose?: boolean
  noTopSide?: boolean //and X button disappear too.
  hFull?: boolean
  bgWhite?: boolean
  border?: boolean
  maxW?: number
}

export const useSidePopup = () => {
  const dispatch = useDispatch()
  const show = (props: SidePopupPayload) => {
    dispatch(popupActions.show({ ...props, type: 'side' }))
  }
  const close = (id) => {
    dispatch(popupActions.close({ id }))
  }
  return { show, close }
}

export const SidePopup = ({ id }: { id: any }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const focusRef = useRef(null)
  const { data, isShow } = useSelector(
    (state: RootState) => ({
      data: state.popup.data[id],
      isShow: state.popup.isPopupRendered,
    }),
    shallowEqual
  )
  const open = data ? true : false
  const {
    title = '',
    body = undefined,
    onClose = null,
    maxW = 375,
    overlayClose = true,
    noTopSide = false,
    hFull = false,
    bgWhite = false,
    border = false,
  } = data ? data : {}

  const closeHandler = () => {
    dispatch(popupActions.close({ id }))
    if (isFunction(onClose)) {
      onClose()
    }
  }

  useModalBackButton(id, isShow)

  useEffect(() => {
    return () => {
      dispatch(popupActions.close({ id }))
    }
  }, [])

  return (
    <div id={id}>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="z-3000 no-scrollbar fixed inset-0 flex items-end justify-end overflow-y-auto overflow-x-hidden"
          initialFocus={focusRef}
          open={open}
          onClose={() => {
            if (overlayClose) {
              closeHandler()
            }
          }}
        >
          <button ref={focusRef} className="hidden" />
          <div className="flex-1">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100">
              <Dialog.Overlay
                className={classNames('fixed inset-0 transition-opacity', {
                  'bg-white-overlay': bgWhite,
                  'bg-black-overlay': !bgWhite,
                })}
              />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span aria-hidden="true" className="hidden sm:align-middle">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-x-20 sm:translate-y-0"
              enterTo="opacity-100 translate-x-0"
            >
              <div className="ml-auto transform overflow-hidden shadow-lg transition-all" style={{ maxWidth: maxW }}>
                <div
                  style={{ height: hFull ? '100vh' : '80vh' }}
                  className={classNames(
                    { 'border-1': border },
                    'no-scrollbar flex flex-col overflow-y-auto bg-white shadow-lg'
                  )}
                >
                  {noTopSide || (
                    <div className="relative m-[0px] flex rounded-t-[8px] px-[20px] py-[16px]">
                      <div className="w-full">
                        <Dialog.Title as="div" className="whitespace-normal pr-20" style={{ wordBreak: 'keep-all' }}>
                          {title}
                        </Dialog.Title>
                      </div>
                      {/* CANCEL BUTTON */}
                      <div className="absolute top-[34px] right-[32px] flex h-[20px] w-[20px] items-center justify-center">
                        <button
                          type="button"
                          className="appearance-none border-none focus:outline-none"
                          style={{ width: 20, height: 20 }}
                          onClick={closeHandler}
                        >
                          <i className="fe fe-x"></i>
                        </button>
                      </div>
                    </div>
                  )}
                  {/* BODY */}
                  <div>{body}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
