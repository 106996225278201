import { useEffect, useState } from 'react'

export default function useIsTablet() {
  const [isTablet, setIsTablet] = useState(false)

  useEffect(() => {
    const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent.toLowerCase() : ''
    setIsTablet(
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      )
    )
  }, [])
  return [isTablet, setIsTablet]
}
