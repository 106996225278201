// @ts-strict-ignore
// API ID
export const API_FETCH_MY_TUTORS = 'api/FETCH_MY_TUTORS' as const
export const API_FETCH_AVAILABLE_TUTORS = 'api/FETCH_AVAILABLE_TUTORS' as const
export const API_FETCH_RISING_STAR_TUTORS = 'api/FETCH_RISING_STAR_TUTORS' as const
export const API_FETCH_ALL_TUTORS = 'api/FETCH_ALL_TUTORS' as const
export const API_FETCH_NEW_UNOCCUPIED_TUTORS = 'api/FETCH_NEW_UNOCCUPIED_TUTORS' as const
export const API_FETCH_OLD_UNOCCUPIED_TUTORS = 'api/FETCH_OLD_UNOCCUPIED_TUTORS' as const
export const API_FETCH_SEARCHED_TUTORS = 'api/FETCH_SEARCHED_TUTORS' as const
export const API_FETCH_TUTOR_PROFILE = 'api/FETCH_TUTOR_PROFILE' as const
export const API_FETCH_TUTOR_OPENED_TIME_SCHEDULE = 'api/FETCH_TUTOR_OPENED_TIME_SCHEDULE' as const
export const API_FETCH_TUTORS_BY_TIME = 'api/FETCH_TUTORS_BY_TIME' as const
export const API_FETCH_TUTOR_SAVE_AND_DELETE = 'api/TUTOR_SAVE_AND_DELETE' as const
export const API_FETCH_SUBMIT_LESSONS = 'api/SUBMIT_LESSONS' as const
export const API_FETCH_SUBMIT_EDIT_MATERIAL = 'api/SUBMIT_EDIT_MATERIAL' as const
export const API_FETCH_SUBMIT_EDIT_TIME_SELECT_MATCHING = 'api/SUBMIT_EDIT_TIME_SELECT_MATCHING' as const
export const API_FETCH_VALIDATE_COUPON = 'api/VALIDATE_COUPON' as const
export const API_FETCH_VALIDATE_COUPON_FOR_EDIT = 'api/VALIDATE_COUPON_FOR_EDIT' as const
export const API_FETCH_UNBOOK = 'api/UNBOOK' as const
export const API_FETCH_MATERIAL_SEARCH = 'api/FETCH_MATERIAL_SEARCH' as const

const init = {
  loading: false,
  data: null,
  error: null,
  payload: null,
}

const initialState = {
  [API_FETCH_TUTOR_PROFILE]: init,
  [API_FETCH_MY_TUTORS]: init,
  [API_FETCH_AVAILABLE_TUTORS]: init,
  [API_FETCH_ALL_TUTORS]: init,
  [API_FETCH_NEW_UNOCCUPIED_TUTORS]: init,
  [API_FETCH_OLD_UNOCCUPIED_TUTORS]: init,
  [API_FETCH_RISING_STAR_TUTORS]: init,
  [API_FETCH_SEARCHED_TUTORS]: init,
  [API_FETCH_TUTORS_BY_TIME]: init,
  [API_FETCH_TUTOR_OPENED_TIME_SCHEDULE]: init,
  [API_FETCH_TUTOR_SAVE_AND_DELETE]: init,
  [API_FETCH_SUBMIT_LESSONS]: init,
  [API_FETCH_SUBMIT_EDIT_MATERIAL]: init,
  [API_FETCH_SUBMIT_EDIT_TIME_SELECT_MATCHING]: init,
  [API_FETCH_VALIDATE_COUPON]: init,
  [API_FETCH_VALIDATE_COUPON_FOR_EDIT]: init,
  [API_FETCH_UNBOOK]: init,
  [API_FETCH_MATERIAL_SEARCH]: init,
}

// action type
export const FETCH = 'fetch/FETCH' as const
export const ASYNC_FETCH = 'fetch/ASYNC_FETCH' as const
const FETCH_SUCCESS = 'fetch/SUCCESS' as const
const FETCH_ERROR = 'fetch/ERROR' as const

// action function
export const fetchAction = (id, call, payload?, callback?) => ({ type: FETCH, id, call, payload, callback })
export const fetchSuccessAction = (id, data) => ({ type: FETCH_SUCCESS, id, data })
export const fetchErrorAction = (id, error) => ({ type: FETCH_ERROR, id, error })

const f = (action, func) => func(action)

const fetchReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASYNC_FETCH:
    case FETCH:
      return f(action, ({ id, payload }) => {
        return {
          ...state,
          [id]: {
            payload: payload,
            loading: true,
            data: null,
            error: null,
          },
        }
      })
    case FETCH_SUCCESS:
      return f(action, ({ id, data }) => {
        return {
          ...state,
          [id]: {
            ...state[id],
            loading: false,
            data: data,
            error: null,
          },
        }
      })
    case FETCH_ERROR:
      return f(action, ({ id, error }) => {
        return {
          ...state,
          [id]: {
            ...state[id],
            loading: false,
            data: null,
            error: error,
          },
        }
      })
    default: {
      return state
    }
  }
}

export default fetchReducer
