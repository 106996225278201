// legacy 버튼 -> 이 파일보다는 DSButton 사용 권장

// @ts-strict-ignore
import Div from '../basic/Div'
import { DivPropType } from '../basic/DivPropsType'
import LoadingSpinner from '../common/LoadingSpinner'

interface ButtonType extends DivPropType {
  primary?: any
  secondary?: any
  black?: any
  blackOutlined?: any
  outlined?: any
  teensOutlined?: any
  outlinedWhite?: any
  outlinedRed?: any
  danger?: any
  warning?: any
  onlyOutlined?: any
  primaryOp20?: any
  hoverPrimary?: any
  hoverSecondary?: any
  disabled?: any
  teens?: any
  lg?: any
  md?: any
  sm?: any
  xs?: any
  children?: any
  clx?: any
  clxComp?: any
  chipMd?: any
  chipSm?: any
  chipSmGray?: any
  chipSmOutlined?: any
  full?: any
  yFull?: any
  flexFull?: any
  lift?: any
  style?: any
  noCursor?: any
  onClick?: any
  tabIndex?: any
  type?: any
  selected?: any
  icon?: any
  aTag?: boolean
  dataTip?: any
  radius?: any
  href?: any
  target?: any
  loading?: boolean
  id?: string
}

const Button = (props: ButtonType) => {
  const {
    primary,
    secondary,
    black,
    blackOutlined,
    outlined,
    teensOutlined,
    outlinedWhite,
    outlinedRed,
    onlyOutlined,
    danger,
    warning,
    primaryOp20,
    hoverPrimary,
    hoverSecondary,
    disabled,
    teens,
    lg,
    md,
    sm,
    xs,
    children,
    clx,
    clxComp,
    chipMd,
    chipSm,
    chipSmGray,
    chipSmOutlined,
    full,
    yFull,
    flexFull,
    lift,
    style,
    noCursor,
    onClick,
    tabIndex,
    type,
    selected,
    icon,
    aTag = false,
    dataTip,
    radius,
    href,
    target,
    loading = false,
    id,
    ...others
  } = props
  return (
    <Div
      dataTip={dataTip}
      buttonTag={!aTag}
      aTag={aTag || href}
      px0
      appearanceNone
      cond={['box-border focus:outline-none cursor-pointer', full && 'w-full', yFull && 'h-full']}
      // 이후 div 태그로 변경할 때 style={false}가 되지 않도록 고려해주어야 합니다
      style={flexFull && { flex: '1' }}
      onClick={onClick}
      tabIndex={tabIndex}
      type={type}
      id={id}
    >
      <Div
        {...others}
        inlineBlock
        fontNormal
        textCenter
        clxComp={clxComp}
        clx={clx}
        // 이후 div 태그로 변경할 때 style={false}가 되지 않도록 고려해주어야 합니다
        style={style}
        cond={[
          'text-h4 leading-snug box-border',
          primary && !disabled && 'border border-primary bg-primary text-white hover:bg-secondary',
          secondary && 'border border-secondary bg-secondary text-white hover:bg-primary',
          teens && 'border border-primary-teens bg-primary-teens text-white hover:opacity-80',
          black && 'border border-black bg-black text-white',
          outlined && selected && 'bg-gray-300 text-primary border border-primary hover:bg-gray-300',
          outlined && !selected && !primaryOp20 && 'bg-white text-primary border border-primary hover:bg-gray-100',
          onlyOutlined && 'text-primary border border-primary',
          blackOutlined && 'bg-white text-black border border-black',
          outlinedRed && 'bg-white text-danger border border-danger',
          danger && 'bg-danger text-white border border-danger',
          warning && 'bg-warning text-black border border-warning',
          outlinedWhite && 'bg-white text-black border border-gray-400 hover:bg-gray-100',
          teensOutlined && 'bg-white text-primary-teens border border-primary-teens hover:bg-gray-100',
          disabled && 'border border-gray-300 bg-gray-300 text-gray-500 hover:bg-gray-300',
          xs && 'btn-xs',
          sm && 'btn-sm',
          md && 'btn-md',
          lg && 'btn-lg',
          !lg && !md && !sm && !xs && 'btn-sm',
          full && 'w-full',
          lift && 'lift',
          chipMd && 'bg-white chip-md text-primary border border-primary hover:bg-opacity-80',
          chipSm && 'bg-primary chip-sm text-white hover:bg-opacity-80 border-primary border',
          chipSmOutlined && 'bg-white chip-sm text-primary border border-primary hover:bg-gray-100',
          noCursor && 'cursor-default',
          primaryOp20 && 'border border-primary bg-primary bg-opacity-20 text-primary hover:bg-opacity-30',
          hoverPrimary && 'hover:text-white hover:bg-primary',
          hoverSecondary && 'hover:text-white hover:bg-secondary',
          icon && 'flex items-center justify-center',
        ]}
      >
        {icon && <img src={icon} className="mr-[8px] h-[20px] w-[20px]" />}

        {loading ? (
          <div className="px-[8px]">
            <LoadingSpinner show md muted={outlined} />
          </div>
        ) : (
          <>
            {href ? (
              <a href={href} target={target ? target : '_self'}>
                {children}
              </a>
            ) : (
              <>{children}</>
            )}
          </>
        )}
      </Div>
    </Div>
  )
}

export default Button
